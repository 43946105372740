export const environment = {
    production: false,
    name: 'dev',
    storagePrefix: 'woonig-dev-',
    firebase: {
        apiKey: 'AIzaSyAuZjGp_EBMGuTOsM6k19DnEYATeZaF7-k',
        authDomain: 'woonig-dev.firebaseapp.com',
        databaseURL: 'https://woonig-dev.firebaseio.com',
        projectId: 'woonig-dev',
        storageBucket: 'woonig-dev.appspot.com',
        messagingSenderId: '492800761410',
        timestampsInSnapshots: true,
        languageDocId: 'HbH3aZPcKE2M2ecsrYcc',
    },
    region: 'us-central1',
    apiBase: 'https://woonig-dev.appspot.com/apiv1/',
    origin: 'http://localhost',
    store_link: {
        ios: 'itms-apps://apps.apple.com/ch/app/woonig-verwalter/id1483426706',
        android: 'https://play.google.com/store/apps/details?id=ch.woonig.manager',
    },
};
